<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link
              :to="'/category/information/' + this.$route.params.id"
              class="btn btn-success mb-3"
            >
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Before Title <strong>*</strong></label
                      >
                      <input
                        v-model="formData.before_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >During Title <strong>*</strong></label
                      >
                      <input
                        v-model="formData.during_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Before Description <strong>*</strong></label
                      >
                      <ckeditor
                        v-model="formData.before_desc"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >During Description <strong>*</strong></label
                      >
                      <ckeditor
                        v-model="formData.during_desc"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >After Title <strong>*</strong></label
                      >
                      <input
                        v-model="formData.after_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >After Description <strong>*</strong></label
                      >
                      <ckeditor
                        v-model="formData.after_desc"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  page: {
    title: "Category Information Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Category Information Update",
      items: [
        {
          text: "Category Information Update",
        },
        {
          text: "Update",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        before_title: null,
        during_title: null,
        before_desc: null,
        during_desc: null,
        after_title: null,
        after_desc: null,
      },
      value1: null,
      options: [],
      urlID: this.$route.params.id,
      urlLangID: this.$route.params.infoId,

      editor: ClassicEditor,
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    FindLp() {
      API.Get(API.Category.Information.UpdateList + this.urlID + "/" + this.urlLangID)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            this.formData.before_title = response.data.order.before_title;
            this.formData.during_title = response.data.order.during_title;
            this.formData.before_desc = response.data.order.before_desc;
            this.formData.during_desc = response.data.order.during_desc;
            this.formData.after_title = response.data.order.after_title;
            this.formData.after_desc = response.data.order.after_desc;
          }
        })
        .catch((error) => {
          console.error("Error while fetching data:", error);
        });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Category.Information.Update + this.urlID + "/" + this.urlLangID,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/category/information/" + this.urlID);
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
